import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import DynamicQuestionnaireMainView from './components/dynamicQuestionnaireMainView';
import DynamicQuestionnairePreface from './components/dynamicQuestionnairePreface';
import DynamicQuestionnaireSidebar from './components/dynamicQuestionnaireSidebar';
import {
  DynamicQuestionnaireCurrentIndexOfSection,
  DynamicQuestionnaireProps,
  HandleOnInputChangeType,
  MedicationStatus,
  ModuleFormErrors,
  RequiredFields,
  ResultList,
  SectionsData,
  TestKitData,
} from './interfaces/dynamicQuestionnaire.interface';
import {
  DynamicQuestionResponseSection,
  DynamicQuestionnaireSectionProgress,
  QuestionnaireResponseInterface,
  childrenInputResponseTypes,
  RequiredIdsList,
  RequiredNestedIdsList,
  GlobalRequiredIdsList,
  DynamicQuestionnaireResponse,
} from './interfaces/dynamicQuestionnaireResponse.interface';
import {
  MEDICATIONS_QUESTION_ID,
  MEDICATIONS_SECTION,
  componentIds,
  genericActionsIds,
  legalDocumentsIds,
  pageIds,
  questionnairesIds,
} from 'utilities/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL,
  DASHBOARD,
  MY_ACCOUNT_PROFILE,
  MY_SKIN,
  TEST_KITS_MANAGEMENT_TEST_KITS,
  NOTIFICATIONS,
  NOTIFICATIONS_UNREAD,
  DNA_VISIT_TERMS_OF_USE,
  AUTH_SEND_VERIFICATION_EMAIL,
  HORMONE_VISIT_TERMS_OF_USE,
  HORMONE_QUESTIONNAIRE_FULL,
} from 'utilities/routes';
import {
  GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery,
  RegisterTestKitMutationVariables,
  useClearAllSavedDnaScannedDataForUserIdMutation,
  useGetDnaTestBySwabcodeIdWithNoErrorsMutation,
  useRegisterTestKitMutation,
  useUpdateProfileNotificationMutation,
} from 'graphql/generated/hasura';

import { AuthContext } from 'auth/context/AuthContext';
import { useGetPage } from 'hooks/useGetPage';
import { isArrayOfStrings, scrollToTop } from 'utilities/functions';
import { useGetComponent } from 'hooks/useGetComponent';
import { useModalParams } from 'components/modal/useModalManager';
import { ResultsPrivacyStatement } from 'app/manage-test-kits/components/ResultsPrivacyStatement';
import { DnaVisitTermsOfUse } from 'app/manage-test-kits/components/DnaVisitTermsOfUse';
import { ProfileSectionTypes } from 'app/my-account/pages/my-profile/enums';
import { useAcceptedLegalDocument } from 'hooks/legalDocuments/useAcceptedLegalDocument';
import { useGetLegalDocument } from 'hooks/legalDocuments/useGetLegalDocument';
import AlertComponent, { AlertType } from 'components/alertComponent';
import useUserValidation from 'hooks/useUserValidation';
import * as _ from 'lodash';
import {
  processSignUpProviderForm,
  removeParentEntriesFromPersistentData,
} from './utils/utils';
import { accumulateRequiredValues } from './utils/utils';
import {
  HORMONE_QUESTIONNAIRE_SECTIONS,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS,
  SPECIFIC_UNAUTHENTICATED_SECTION,
} from './utils/constants';
import { useGoogleSignupWithCredentialsForProvider } from '../../firebase/hooks';
import { FIREBASE_ERROR_CODES } from '../../firebase/interfaces';
import {
  FhirQuestionnaireNames,
  useGetLatestFhirQuestionnaireByIdQuery,
  useInsertFhirQuestionnaireResponseMutation,
  useSaveFhirQuestionnaireManuallyMutation,
  useUpdateFhirUserLatestQuestionnaireMutation,
} from 'graphql/generated/remote-schema-hasura';

export const DynamicQuestionnaire: React.FC<DynamicQuestionnaireProps> = ({
  id,
  response,
  testKitData = {},
  questionnaireStoredData,
  enableSubmitCheckbox = {
    enable: false,
    content: null,
    highlightLink: false,
    link: null,
    showLinkOnAllViews: false,
  },
  refetchData,
  isUnauthenticatedQuestionnaire = false,
}) => {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>('positive');
  const location = useLocation();
  const referringPage = location.state?.referringPage || '';
  const isDnaTestKitsFlow = location.state?.isDnaTestKitsFlow || false;
  const [userCompletedData, setUserCompletedData] = useState({
    profile: false,
    contact: false,
    healthQuestionnaire: false,
  });

  const { userValidation, loading: loadingUserValidation } =
    useUserValidation();

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CONTINUE,
        genericActionsIds.SUBMIT,
        genericActionsIds.SUBMIT_FORM,
      ],
    });

  const { user: loggedUser } = useContext(AuthContext);

  const { data: locale, loading: testKitsTextsLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.REGISTER_TEST_KIT,
  });

  const {
    data: dynamicQuestionnaireLocale,
    loading: dynamicQuestionnaireLocaleLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DYNAMIC_QUESTIONNAIRE,
  });

  const [updateProfileNotification] = useUpdateProfileNotificationMutation({});

  const [registerTestKitMutation, { loading: isSavingDNAQuestionnaire }] =
    useRegisterTestKitMutation({});

  // For health questionnaire, we need to keep the questionnaire response history.
  // We need to insert everytime instead of patching
  const [
    insertFhirQuestionnaireResponses,
    { loading: isSavingFhirQuestionnaireResponses },
  ] = useInsertFhirQuestionnaireResponseMutation({});

  const [
    getDnaTestBySwabCodeIDWithNoErrors,
    { loading: isFetchingRepeatedDNATests },
  ] = useGetDnaTestBySwabcodeIdWithNoErrorsMutation();

  const [saveFhirQuestionnaireManually] =
    useSaveFhirQuestionnaireManuallyMutation({});

  const [updateFHIRUserLatestQuestionnaireMutation] =
    useUpdateFhirUserLatestQuestionnaireMutation({});

  const { legalDocument: dnaVisitRecordConsentDocument } = useGetLegalDocument({
    legalDocumentId: legalDocumentsIds.DNA_VISIT_RECORD_CONSENT,
  });

  const { hasAcceptedLegalDocument } = useAcceptedLegalDocument();

  const [
    providerData,
    signUpProviderFirebaseError,
    loadingProvider,
    providerErrorCode,
    handleGoogleSignupWithCredentialsForProvider,
  ] = useGoogleSignupWithCredentialsForProvider();

  const [currentSection, setCurrentSection] =
    useState<DynamicQuestionResponseSection>(response.data[0]);

  const [currentIndexOfSection, setCurrentIndexOfSection] =
    useState<DynamicQuestionnaireCurrentIndexOfSection>({
      length: 0,
      index: 0,
    });

  const [questionnaireData, setQuestionnaireData] = useState<
    Array<QuestionnaireResponseInterface>
  >([]);

  const [sectionsProgress, setSectionsProgress] = useState<
    Array<DynamicQuestionnaireSectionProgress>
  >([]);

  const [persistentData, setPersistentData] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [radioCheckedState, setRadioCheckedState] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [checkboxesCheckedState, setCheckboxesCheckedState] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [textFieldResponses, setTextFieldResponses] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [selectsFromStorage, setSelectsFromStorage] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [medicationsDataFromStorage, setMedicationsDataFromStorage] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [isSubmitCheckboxChecked, setIsSubmitCheckboxChecked] = useState(false);

  const [isSidebarBlocked, setIsSidebarBlocked] = useState(false);

  const [hormoneTableMedicationsSelected, setHormoneTableMedicationsSelected] =
    useState<QuestionnaireResponseInterface[]>([]);

  const [
    hormoneTableCheckboxesCheckedState,
    setHormoneTableCheckboxesCheckedState,
  ] = useState<QuestionnaireResponseInterface[]>([]);

  const [hormoneTableCheckboxCheckAll, setHormoneTableCheckboxAll] =
    useState(false);

  const isCurrentSectionComplete = useMemo(() => {
    const sectionProgress = sectionsProgress.find(
      (section) => section.name === currentSection.name,
    );
    return sectionProgress && sectionProgress.complete;
  }, [sectionsProgress, currentSection.name]);
  const [submitWasTriggered, setSubmitWasTriggered] = useState<boolean>(false);

  const [moduleFormErrors, setModuleFormErrors] =
    useState<ModuleFormErrors | null>(null);

  const [clearAllSavedDnaScannedDataForUserIdMutation] =
    useClearAllSavedDnaScannedDataForUserIdMutation({
      variables: {
        userId: loggedUser?.uuid || '',
      },
    });

  const evaluateDataChangeOnQuestionnaireData = useCallback(() => {
    if (
      !questionnaireStoredData ||
      !questionnaireStoredData.questionnaire_responses ||
      questionnaireStoredData.questionnaire_responses.length === 0 ||
      !questionnaireStoredData.questionnaire_responses[0].responses
    ) {
      return;
    }

    return (
      refetchData &&
      refetchData().then((data) => {
        const storageData = data.questionnaire_responses[0].responses;

        const currentData = questionnaireData;
        return JSON.stringify(currentData) === JSON.stringify(storageData);
      })
    );
  }, [questionnaireStoredData, refetchData, questionnaireData]);

  const hasQuestionnaireResponsesInStorage =
    questionnaireStoredData &&
    questionnaireStoredData.questionnaire_responses[0] &&
    questionnaireStoredData.questionnaire_responses[0].responses.length >= 1;

  const calculateAllSidebarItemsProgress = useMemo(() => {
    const checkArrayMatch = (
      arr1: QuestionnaireResponseInterface[],
      arr2: string[],
    ): boolean => {
      const result = arr2.every((id: string) => {
        const found = arr1.some(
          (item: QuestionnaireResponseInterface) =>
            item.questionID?.toString() === id,
        );
        return found;
      });
      return result;
    };

    const checkArrayMatchInHealthQuestionnaire = (
      arr1: QuestionnaireResponseInterface[],
      arr2: string[],
      sectionName: string,
    ): ResultList[] | boolean => {
      const results: ResultList[] = [];

      const accumulativeStatusOfMedications: MedicationStatus[] = [];

      const requiredFields = response.data.map((obj) => {
        const requiredFieldsList: RequiredFields[] = [];
        const accumulatedValues = accumulateRequiredValues(
          obj.dependencies ?? [],
        );

        const sectionValues: RequiredFields = {
          name: obj.name,
          values: accumulatedValues,
        };

        requiredFieldsList.push(sectionValues);
        return sectionValues;
      });

      const matchedSection = requiredFields.find(
        (section) => section.name === sectionName,
      );

      const findMismatchedID = (
        answers: QuestionnaireResponseInterface[],
        values: string[],
      ) => {
        const matchedIDs = answers.map((answer) =>
          answer?.questionID?.toString(),
        );

        for (const value of values) {
          if (!matchedIDs.includes(value)) {
            return value;
          }
        }

        return null; // If no mismatch found
      };

      // we will have to extend this to use for signup provider
      if (matchedSection) {
        const matchedValues = matchedSection.values;

        if (sectionName === MEDICATIONS_SECTION) {
          const arrayOfMedications = arr1.find(
            (id) => id.questionID === MEDICATIONS_QUESTION_ID,
          );
          if (
            Array.isArray(arrayOfMedications?.response) &&
            arrayOfMedications?.response.length === 0
          ) {
            // medications field is empty
            return true;
          } else {
            if (Array.isArray(arrayOfMedications?.response)) {
              arrayOfMedications?.response.forEach((medication) => {
                if (Array.isArray(medication.answers)) {
                  const missingID = findMismatchedID(
                    medication?.answers ?? [],
                    matchedValues,
                  );

                  let statusOfMedication: MedicationStatus = {
                    id: (medication?.id ?? '').toString(),
                    status: true,
                  };

                  if (missingID) {
                    accumulativeStatusOfMedications.push(statusOfMedication);
                    statusOfMedication = {
                      ...statusOfMedication,
                      status: false,
                    };
                  }

                  accumulativeStatusOfMedications.push(statusOfMedication);
                }
              });
            }
          }

          const statusOfMedicationList = accumulativeStatusOfMedications.every(
            (status) => {
              if (status.status === true) {
                return true;
              } else {
                return false;
              }
            },
          );

          return statusOfMedicationList;
        } else {
          matchedValues.every((id: string) => {
            const found = arr1.some(
              (item: QuestionnaireResponseInterface) =>
                item.questionID?.toString() === id,
            );
            results.push({ name: sectionName, status: found });
            return found;
          });
        }
      }
      return results;
    };

    if (hasQuestionnaireResponsesInStorage) {
      const sectionCompletionStatus: Record<string, boolean> = {}; // Object to track completion status for each section

      response.data.forEach((section) => {
        sectionCompletionStatus[section.name] = false; // Initialize each section as incomplete

        response.data.forEach((sectionProgress) => {
          const matchFound = checkArrayMatch(
            questionnaireStoredData?.questionnaire_responses[0]?.responses,
            sectionProgress.properties,
          );

          switch (id) {
            case questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT:
              if (sectionProgress.name === section.name && matchFound) {
                sectionCompletionStatus[section.name] = true;
              }
              break;
            case questionnairesIds.HEALTH_QUESTIONNAIRE: {
              const matchFoundInHealthQuestionnaire =
                checkArrayMatchInHealthQuestionnaire(
                  questionnaireStoredData?.questionnaire_responses[0]
                    ?.responses,
                  sectionProgress.properties,
                  section.name,
                );

              if (
                sectionProgress.name === section.name &&
                Array.isArray(matchFoundInHealthQuestionnaire)
              ) {
                sectionCompletionStatus[section.name] =
                  matchFoundInHealthQuestionnaire.every(
                    (item) => item.status === true,
                  );
              }
              if (
                sectionProgress.name === section.name &&
                section.name === 'Medications'
              ) {
                sectionCompletionStatus[section.name] =
                  matchFoundInHealthQuestionnaire as boolean;
              }
              break;
            }
            default:
              sectionCompletionStatus[section.name] = false;
              break;
          }
        });
      });

      const newItemsResponse = response.data.map((section) => ({
        name: section.name,
        complete: sectionCompletionStatus[section.name],
      }));
      return newItemsResponse;
    } else {
      return [];
    }
  }, [
    hasQuestionnaireResponsesInStorage,
    response.data,
    questionnaireStoredData?.questionnaire_responses,
    id,
  ]);

  const [globalNestedRequiredIds, setGlobalNestedRequiredIds] =
    useState<GlobalRequiredIdsList>();
  const [requiredIds, setRequiredIds] = useState<RequiredIdsList>([]);
  const [nestedRequiredIds, setNestedRequiredIds] =
    useState<RequiredNestedIdsList>([]);

  const sidebarItemsWithProgress = useMemo(() => {
    const newItemsResponse = response.data.map((section) => {
      let progressOfSection = false;
      sectionsProgress.forEach((sectionProgress) => {
        if (sectionProgress.name === section.name)
          progressOfSection = sectionProgress.complete;
      });
      return {
        ...section,
        complete: progressOfSection,
      };
    });

    return newItemsResponse;
  }, [response.data, sectionsProgress]);

  const doesMainQuestionResponseExist = (
    filteredData: QuestionnaireResponseInterface[],
    questionID: string,
  ): boolean => {
    for (const response of filteredData) {
      if (response.questionID?.toString() === questionID) {
        if (typeof response.response === 'string') {
          return Boolean(response.response);
        }

        if (Array.isArray(response.response) && response.response.length) {
          if (isArrayOfStrings(response.response)) return true;

          const mainQuestionResponse = response.response.find(
            (res) => res.questionID?.toString() === questionID,
          );

          if (mainQuestionResponse) {
            if (typeof mainQuestionResponse.response === 'string') {
              return Boolean(response.response);
            }

            if (Array.isArray(mainQuestionResponse.response)) {
              return Boolean(mainQuestionResponse.response.length);
            }
          }
        }
      }
    }

    return false;
  };

  const doesNestedQuestionResponseExist = (
    filteredData: QuestionnaireResponseInterface[],
    mainQuestion: string,
    questionID: string,
  ): boolean => {
    for (const response of filteredData) {
      if (response.questionID?.toString() === mainQuestion) {
        if (Array.isArray(response.response) && response.response.length) {
          if (isArrayOfStrings(response.response)) return true;

          const questionIdResponse = response.response.find(
            (res) => res.questionID?.toString() === questionID,
          );

          if (questionIdResponse) {
            if (typeof questionIdResponse.response === 'string') {
              return Boolean(questionIdResponse.response);
            }

            if (Array.isArray(questionIdResponse.response)) {
              return Boolean(questionIdResponse.response.length);
            }
          }
        }
      }
    }

    return false;
  };

  const searchForRequiredResponse = (
    filteredData: QuestionnaireResponseInterface[],
    mainQuestion: string,
    questionID: string,
    expectedResponse: string,
  ): boolean => {
    for (const response of filteredData) {
      if (response.questionID?.toString() === mainQuestion) {
        // User select radio option
        if (typeof response.response === 'string') {
          return response.response === expectedResponse;
        }

        if (Array.isArray(response.response) && response.response.length) {
          const questionIdResponse = response.response.find(
            (res) => res.questionID?.toString() === questionID,
          );

          if (questionIdResponse) {
            // User select one option in a list of checkboxes
            if (typeof questionIdResponse.response === 'string') {
              return questionIdResponse.response === expectedResponse;
            }

            // User select more than one option in a list of checkboxes
            if (
              Array.isArray(questionIdResponse.response) &&
              questionIdResponse.response.length
            ) {
              return questionIdResponse.response.some(
                (res) => res.response === expectedResponse,
              );
            }
          }
        }
      }
    }

    return false;
  };

  const lengthValidationPerSection = useCallback(() => {
    const filteredData = questionnaireData.filter((section) => {
      if (section.currentSection === currentSection.name) {
        return section;
      }
    });

    const getValidationPerSection = (): boolean => {
      const requiredSectionDependencies =
        currentSection.dependencies?.filter(
          (dependency) => dependency.then.required,
        ) || [];

      const requiredParentIds: RequiredIdsList = [];
      const requiredNestedIds: RequiredNestedIdsList = [];

      requiredSectionDependencies.forEach((dependency) => {
        if (dependency.if) {
          const mainQuestion = dependency.if.mainQuestion;
          const properties = dependency.if.properties;
          const questionId = Object.keys(dependency.if.properties)[0];

          if (
            properties &&
            dependency.then &&
            dependency.then.required &&
            dependency.then.required.length
          ) {
            const requiredId = dependency.then.required[0];

            let isRequired = true;
            let isNestedQuestion = true;
            let index = 0;

            for (const property in properties) {
              // If it's a main question, add to required array.
              if (properties[property].const === true) {
                isNestedQuestion = false;
              } else {
                // If it's nested question, let's evaluate the parent response.
                const questionId = Object.keys(dependency.if.properties)[index];
                const requiredResponse = properties[property].const as string;

                isRequired =
                  isRequired &&
                  searchForRequiredResponse(
                    filteredData,
                    mainQuestion || questionId,
                    questionId,
                    requiredResponse,
                  );
              }

              index++;
            }

            if (isRequired) {
              if (isNestedQuestion) {
                requiredNestedIds.push({
                  [mainQuestion || questionId]: requiredId,
                });
              } else {
                requiredParentIds.push(requiredId);
              }
            }
          }
        }
      });

      setRequiredIds(requiredParentIds);
      setNestedRequiredIds(requiredNestedIds);
      setGlobalNestedRequiredIds((prevState) => {
        return {
          ...prevState,
          [currentSection.name]: [...requiredNestedIds],
        };
      });

      if (id === questionnairesIds.HEALTH_QUESTIONNAIRE) {
        if (currentSection.name === 'Medications') {
          if (filteredData[0] && filteredData[0].response) {
            const medications = Object.values(filteredData[0].response);
            return medications.every((medication) =>
              requiredParentIds.every((id) =>
                medication.answers.some(
                  (answer: QuestionnaireResponseInterface) => {
                    if (answer.questionID === 79 || answer.questionID === 81) {
                      const answer1 = medication.answers.find(
                        (answer: QuestionnaireResponseInterface) =>
                          answer.questionID?.toString() === id &&
                          (answer.response as { id: string; value: string })
                            .id === 'answer-1' &&
                          (answer.response as { id: string; value: string })
                            .value,
                      );
                      const answer2 = medication.answers.find(
                        (answer: QuestionnaireResponseInterface) =>
                          answer.questionID?.toString() === id &&
                          (answer.response as { id: string; value: string })
                            .id === 'answer-2' &&
                          (answer.response as { id: string; value: string })
                            .value,
                      );

                      return !!answer1 && !!answer2;
                    } else {
                      return answer.questionID?.toString() === id;
                    }
                  },
                ),
              ),
            );
          }
        } else {
          return requiredParentIds.every((requiredParentId) => {
            return doesMainQuestionResponseExist(
              filteredData,
              requiredParentId,
            );
          });
        }
      } else if (id === questionnairesIds.SIGN_UP_PROVIDER) {
        if (
          currentSection.name ===
          SPECIFIC_UNAUTHENTICATED_SECTION.CRENDENTIALS_AND_CERTIFICATIONS
        ) {
          if (filteredData[0] && filteredData[0].response) {
            const educations = Object.values(filteredData[0].response);
            const localRequiredParentDynamicIds = [
              SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_SCHOOL.toString(),
              SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_RESIDENCY.toString(),
              SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_UNDERGRADUATE.toString(),
            ];

            const dynamicIdsArePresent = educations.every((education) =>
              localRequiredParentDynamicIds.every((id) => {
                return (
                  education.answers &&
                  education.answers.some(
                    (answer: QuestionnaireResponseInterface) => {
                      return answer.questionID?.toString() === id;
                    },
                  )
                );
              }),
            );

            const localStaticParentIds = requiredParentIds.filter(
              (id) => !localRequiredParentDynamicIds.includes(id),
            );

            const parentIdsArePresent = localStaticParentIds.every(
              (requiredParentId) => {
                return doesMainQuestionResponseExist(
                  filteredData,
                  requiredParentId,
                );
              },
            );

            return dynamicIdsArePresent && parentIdsArePresent;
          }
        } else {
          return requiredParentIds.every((requiredParentId) => {
            return doesMainQuestionResponseExist(
              filteredData,
              requiredParentId,
            );
          });
        }
      } else if (
        id === questionnairesIds.HORMONE_QUESTIONNAIRE_MALE &&
        currentSection.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS
      ) {
        // Allow for validations in module to take over
        return true;
      } else if (
        id === questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE &&
        currentSection.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS
      ) {
        // Allow for validations in module to take over
        return true;
      } else {
        const parentQuestionsCompleted = requiredParentIds.every(
          (requiredParentId) => {
            return doesMainQuestionResponseExist(
              filteredData,
              requiredParentId,
            );
          },
        );

        const nestedQuestionsCompleted = requiredNestedIds.every(
          (nestedRequiredId) => {
            const parentId = Object.keys(nestedRequiredId)[0];
            const questionId = nestedRequiredId[parentId];

            return doesNestedQuestionResponseExist(
              filteredData,
              parentId,
              questionId,
            );
          },
        );

        return parentQuestionsCompleted && nestedQuestionsCompleted;
      }
      return false;
    };

    const newObj: DynamicQuestionnaireSectionProgress = {
      name: currentSection.name,
      complete: getValidationPerSection(),
    };

    const updateSectionsProgress = (
      prevSectionsProgress: DynamicQuestionnaireSectionProgress[],
    ) => {
      const updatedSectionsProgress = prevSectionsProgress.map(
        (sectionProgress) =>
          sectionProgress.name === currentSection.name
            ? newObj
            : sectionProgress,
      );

      const isSectionProgressEntryDuplicate = prevSectionsProgress.some(
        (sectionProgress) => sectionProgress.name === currentSection.name,
      );

      const updatedState = isSectionProgressEntryDuplicate
        ? updatedSectionsProgress
        : [...updatedSectionsProgress, newObj];

      return updatedState;
    };

    setSectionsProgress(updateSectionsProgress);
  }, [questionnaireData, currentSection.name, currentSection.dependencies, id]);

  const getNextSection = (enableClearAlertMessage = true) => {
    const newPos = currentIndexOfSection.index + 1;
    if (newPos < currentIndexOfSection.length) {
      setCurrentIndexOfSection({
        ...currentIndexOfSection,
        index: newPos,
      });
      setCurrentSection(response.data[newPos]);
      lengthValidationPerSection();
      enableClearAlertMessage && setAlertMessage(null);
    }
    if (newPos === currentIndexOfSection.length) {
      const getIncompleteSection = getFirstIncompleteSection(sectionsProgress);
      const newSection: DynamicQuestionnaireCurrentIndexOfSection = {
        index: getIncompleteSection,
        length: currentIndexOfSection.length,
      };

      setAlertMessage(locale.completeTheQuestionnaire);
      setAlertType('warning');
      setCurrentSection(response.data[getIncompleteSection]);
      setCurrentIndexOfSection(newSection);
    }

    window.setTimeout(() => {
      scrollToTop();
    }, 200);
  };

  const getCurrentSectionPosition = useMemo(() => {
    return {
      length: response.data.length,
      index: response.data.indexOf(currentSection),
    };
  }, [response, currentSection]);

  const handleSidebarClick = (itemClicked: string) => {
    const currentSelectedSection = response.data.filter((section) => {
      return itemClicked === section.name;
    });
    setCurrentSection(currentSelectedSection[0]);
    lengthValidationPerSection();
  };

  const debounce = (
    func: HandleOnInputChangeType,
    delay: number,
  ): ((...args: Parameters<HandleOnInputChangeType>) => void) => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return (...args: Parameters<HandleOnInputChangeType>) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleOnInputChange: HandleOnInputChangeType = (
    response,
    questionID,
    childrenInputResponse,
    childrenInputParentID,
    childrenOriginalQuestionID,
    thirdLevelNesting,
  ) => {
    let cleanedResponse:
      | number
      | string
      | QuestionnaireResponseInterface[]
      | [] = response;

    // Clean response from unnecesary properties
    if (Array.isArray(response)) {
      cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        delete cleanedItem.checked;
        delete cleanedItem.section;
        return cleanedItem;
      });
    }

    // Create incomingData object in order to present the final data
    const incomingData = {
      currentSection: currentSection.name,
      currentIndexOfSection: currentIndexOfSection.index,
      response: cleanedResponse,
      questionID: questionID,
    };

    // Find matching current index
    const matchingIndex = persistentData.findIndex(
      (entry: QuestionnaireResponseInterface) =>
        entry.currentSection === incomingData.currentSection &&
        entry.questionID === incomingData.questionID,
    );

    // 1st level of question
    if (
      matchingIndex === -1 &&
      !childrenInputParentID &&
      !thirdLevelNesting &&
      !childrenInputResponse
    ) {
      const newData: QuestionnaireResponseInterface = {
        currentSection: incomingData.currentSection,
        currentIndexOfSection: incomingData.currentIndexOfSection,
        questionID: incomingData.questionID,
        response: incomingData.response,
      };

      const newPersistentData = [...persistentData, newData];

      setPersistentData(newPersistentData);
      return;
    }

    const existingEntry =
      matchingIndex !== -1 ? persistentData[matchingIndex] : null;
    // If a response exists, replace it, otherwise create a new one
    const newEntry: QuestionnaireResponseInterface = existingEntry
      ? {
          ...existingEntry,
          response: Array.isArray(existingEntry.response)
            ? [...existingEntry.response]
            : existingEntry.response,
        }
      : {
          currentSection: incomingData.currentSection,
          currentIndexOfSection: incomingData.currentIndexOfSection,
          questionID: incomingData.questionID,
          response: incomingData.response,
        };

    const updatedData = [...persistentData];

    // If existing entry exists and response is an array (checkboxes), update the entry
    if (existingEntry && Array.isArray(existingEntry.response)) {
      updatedData[matchingIndex] = newEntry;
      setPersistentData(updatedData);
    } else if (existingEntry && !Array.isArray(existingEntry.response)) {
      if (id === questionnairesIds.HEALTH_QUESTIONNAIRE) {
        updatedData[matchingIndex] = {
          ...updatedData[matchingIndex],
          response: incomingData.response,
        };
      } else {
        // If existing entry exists and response is text (not array), update the entry
        updatedData[matchingIndex] = {
          ...updatedData[matchingIndex],
          response: incomingData.response,
        };
      }

      setPersistentData(updatedData);
    }

    // If the question has a children, start proccessing it here
    if (childrenInputParentID) {
      let newInputResponse: QuestionnaireResponseInterface = {
        questionID: 0,
        response: '',
      };

      if (childrenInputResponse) {
        newInputResponse = {
          questionID: Number(childrenOriginalQuestionID),
          response: childrenInputResponse,
        };
      } else {
        newInputResponse = {
          questionID: Number(childrenOriginalQuestionID),
          response: incomingData.response,
        };
      }

      // If children response is an array (checkboxes)
      if (Array.isArray(newEntry.response)) {
        const parentResponseIndex = newEntry.response.findIndex(
          (item: QuestionnaireResponseInterface) =>
            item.questionID === Number(childrenOriginalQuestionID),
        );

        if (parentResponseIndex !== -1) {
          if (childrenInputResponse) {
            const newItem = {
              ...newEntry.response[parentResponseIndex],
              response: childrenInputResponse,
            };

            newEntry.response[parentResponseIndex] = newItem;
          } else {
            const mergedResponses = incomingData.response
              ? incomingData.response
              : [];

            if (childrenInputResponse?.length === 0) {
              newEntry.response[parentResponseIndex].response =
                childrenInputResponse;
            }

            // Create a new array by spreading mergedResponses and pushing newInputResponse
            if (Array.isArray(mergedResponses)) {
              newEntry.response = [...mergedResponses, newInputResponse];
            }
          }
        } else {
          // Create a new array by spreading the existing responses and pushing newInputResponse
          newEntry.response = [...newEntry.response, newInputResponse];
        }

        // If there's an existing entry and we're on child response
        // Detect what kind of response it is (checkboxes/single text) and process it
        // By either merging the previous responses, creating a new one or updating
        const updatedData = [...persistentData];

        if (existingEntry) {
          updatedData[matchingIndex] = newEntry;
          setPersistentData(updatedData);
        } else {
          // Handles mixing of checkboxes with single response
          const replaceThisItem: QuestionnaireResponseInterface[] =
            updatedData.filter(
              (item: QuestionnaireResponseInterface) =>
                item.questionID === childrenInputParentID,
            );

          if (
            replaceThisItem[0] &&
            typeof replaceThisItem[0].response !== undefined
          ) {
            if (replaceThisItem && Array.isArray(replaceThisItem[0].response)) {
              const replaceThisResponseIndex =
                replaceThisItem[0].response.findIndex(
                  (item: QuestionnaireResponseInterface) =>
                    item.questionID === incomingData.questionID,
                );

              if (replaceThisResponseIndex !== -1) {
                const updatedResponse = [...replaceThisItem[0].response];
                updatedResponse[replaceThisResponseIndex] = {
                  ...replaceThisItem[0].response[replaceThisResponseIndex],
                  response: incomingData.response,
                };

                const updatedItem = {
                  ...replaceThisItem[0],
                  response: updatedResponse,
                };

                replaceThisItem[0] = updatedItem;

                const updateData = [...persistentData];

                const existingItemIndex = updateData.findIndex(
                  (item: QuestionnaireResponseInterface) =>
                    item.questionID === replaceThisItem[0].questionID,
                );

                if (existingItemIndex !== -1) {
                  updateData[existingItemIndex] = { ...replaceThisItem[0] };

                  // Update the state variable with the modified data
                  setPersistentData(updateData);
                  return;
                }
              }
            } else {
              if (Array.isArray(replaceThisItem[0].response)) {
                const previousStringResponse = replaceThisItem[0].response.find(
                  (item: QuestionnaireResponseInterface) =>
                    item.questionID === childrenInputParentID,
                );

                const newResponseArray: QuestionnaireResponseInterface[] = [];

                const previousResponse = {
                  questionID: previousStringResponse?.questionID,
                  response: previousStringResponse?.response,
                };

                const existingIndex = newResponseArray.findIndex(
                  (item: QuestionnaireResponseInterface) =>
                    item.questionID === previousResponse.questionID,
                );

                if (existingIndex !== -1) {
                  newResponseArray[existingIndex] = previousResponse;
                } else {
                  newResponseArray.push(previousResponse);
                }

                replaceThisItem[0].response = previousResponse;

                if (Array.isArray(incomingData.response)) {
                  incomingData.response.forEach(
                    (item: QuestionnaireResponseInterface) => {
                      newResponseArray.push(item);
                    },
                  );
                }
                replaceThisItem[0].response = newResponseArray;
              } else {
                const newArray = [];
                const previousItem = {
                  questionID: replaceThisItem[0].questionID,
                  response: replaceThisItem[0].response,
                };

                newArray.push(previousItem);

                if (Array.isArray(incomingData.response)) {
                  incomingData.response.forEach((item) => {
                    newArray.push(item);
                  });
                }

                replaceThisItem[0].response = newArray;
              }
            }
          }
          setPersistentData([...persistentData]);
        }
      } else if (
        thirdLevelNesting === 'TEXT' &&
        typeof newEntry.response === 'string'
      ) {
        const checkedRadio = radioCheckedState.find((item) => {
          if (item.questionID === newEntry.questionID) {
            return item;
          }
        });

        const newAnswer = {
          ...newEntry,
          response: [
            {
              questionID: checkedRadio?.questionID,
              response: checkedRadio?.response,
            },
            {
              questionID: Number(childrenOriginalQuestionID),
              response: childrenInputResponse,
            },
          ],
        };

        // Use item.questionID for normal questions and item.inputId for double input fields.
        const existingIndex = persistentData.findIndex((item) => {
          const existingId = item.inputId ?? item.questionID;
          const newEntryId = newEntry.inputId ?? newEntry.questionID;
          return existingId === newEntryId;
        });

        if (existingIndex !== -1) {
          const updatedPersistentData = [...persistentData];
          updatedPersistentData[existingIndex] = newAnswer;
          setPersistentData(updatedPersistentData);
        } else {
          setPersistentData([...persistentData, newAnswer]);
        }
      } else {
        // If the children has inputResponse, we detect childrenInputResponse
        // And process it accordingly
        if (childrenInputResponse) {
          newEntry.response = {
            questionID: Number(childrenOriginalQuestionID),
            response: childrenInputResponse,
          };
          // In the case of parent -> children -> subchildren input field, we detect it with thirdLevelNesting and process it
          if (thirdLevelNesting) {
            const parentItem = persistentData.find(
              (item: QuestionnaireResponseInterface) => {
                return item.questionID === childrenInputParentID;
              },
            );

            if (parentItem && parentItem.response) {
              // Find the parent response for the current child question
              let parentResponse: QuestionnaireResponseInterface | undefined =
                undefined;

              if (Array.isArray(parentItem.response)) {
                parentResponse = parentItem.response.find(
                  (item: QuestionnaireResponseInterface) =>
                    item.questionID === questionID,
                );
              }

              if (parentResponse) {
                // If parentResponse with the same ID exists, update its response
                parentResponse.response = childrenInputResponse;
              } else {
                if (Array.isArray(parentItem.response)) {
                  const newResponse: QuestionnaireResponseInterface = {
                    questionID: questionID,
                    response: childrenInputResponse,
                  };
                  (
                    parentItem.response as QuestionnaireResponseInterface[]
                  ).push(newResponse);
                }
              }
              const indexToReplace = persistentData.findIndex(
                (item: QuestionnaireResponseInterface) => item === parentItem,
              );

              const updatedData = [...persistentData];
              updatedData[indexToReplace] = parentItem;
              setPersistentData(updatedData);
            }
          }
        } else {
          newEntry.response = incomingData.response;
        }

        // Handles mix inputs of single text / arrays and process them
        newEntry.response = incomingData.response;

        if (childrenInputResponse?.length === 0) {
          newEntry.response = '';
        }

        const indexToReplace = persistentData.findIndex(
          (item: QuestionnaireResponseInterface) =>
            item.questionID === newEntry.questionID,
        );

        if (indexToReplace !== -1) {
          const updatedData = [...persistentData];
          updatedData[indexToReplace] = newEntry;
          setPersistentData(updatedData);
        }

        const currentParentItem = persistentData.find(
          (item: QuestionnaireResponseInterface) =>
            item.questionID === childrenInputParentID,
        );

        const parentIndexToReplace = persistentData.findIndex(
          (item: QuestionnaireResponseInterface) =>
            item.questionID === childrenInputParentID,
        );

        if (parentIndexToReplace !== -1) {
          const updatedData = [...persistentData];

          if (Array.isArray(updatedData[parentIndexToReplace].response)) {
            const responseToReplace = (
              updatedData[parentIndexToReplace]
                .response as QuestionnaireResponseInterface[]
            ).find(
              (item: QuestionnaireResponseInterface) =>
                item.questionID === incomingData.questionID,
            );

            if (!childrenInputResponse && responseToReplace) {
              // Create a deep copy of the object to be modified
              const updatedResponseToReplace = JSON.parse(
                JSON.stringify(responseToReplace),
              );

              updatedResponseToReplace.response = incomingData.response;

              if (childrenInputResponse?.length === 0) {
                updatedResponseToReplace.response = '';
              }

              const filteredDataWithPreviousResponseRemoved = (
                updatedData[parentIndexToReplace]
                  .response as QuestionnaireResponseInterface[]
              ).filter(
                (item: QuestionnaireResponseInterface) =>
                  !(item.questionID === updatedResponseToReplace?.questionID),
              );

              newEntry.response = [
                ...filteredDataWithPreviousResponseRemoved,
                updatedResponseToReplace,
              ];

              // Create a deep copy of the object to be modified
              const updatedParentObject = JSON.parse(
                JSON.stringify(updatedData[parentIndexToReplace]),
              );

              updatedParentObject.response = newEntry.response;

              updatedData[parentIndexToReplace] = updatedParentObject;

              setPersistentData(updatedData);
              return;
            } else {
              // Handles if 3rd level children input response is found
              const incomingThirdLevelEntry: QuestionnaireResponseInterface = {
                questionID: questionID,
                response: childrenInputResponse,
              };
              const findParentEntry = (
                updatedData[parentIndexToReplace]
                  .response as QuestionnaireResponseInterface[]
              ).find((item: QuestionnaireResponseInterface) => {
                let parentResponse: QuestionnaireResponseInterface = {
                  questionID: 0,
                  response: [],
                };

                if (item.questionID === Number(childrenOriginalQuestionID)) {
                  parentResponse = item;
                }

                return parentResponse;
              });

              if (findParentEntry && Array.isArray(findParentEntry.response)) {
                const findRepeatedThirdInputIndex =
                  findParentEntry.response.find(
                    (item: QuestionnaireResponseInterface) =>
                      item.questionID === incomingThirdLevelEntry.questionID,
                  );

                if (findRepeatedThirdInputIndex) {
                  // Create a deep copy of the object to be modified
                  const updatedThirdInputIndex = JSON.parse(
                    JSON.stringify(findRepeatedThirdInputIndex),
                  );

                  updatedThirdInputIndex.response = childrenInputResponse;
                }
              }

              setPersistentData(updatedData);
            }
          } else {
            // Handles if not an array
            // Create a deep copy of the object to be modified
            const updatedObjectToReplace = JSON.parse(
              JSON.stringify(updatedData[parentIndexToReplace]),
            );

            updatedObjectToReplace.response = [
              { questionID: newEntry.questionID, response: newEntry.response },
              {
                questionID: currentParentItem?.questionID,
                response: currentParentItem?.response,
              },
            ];

            // Update the array in the original data
            updatedData[parentIndexToReplace] = updatedObjectToReplace;

            setPersistentData(updatedData);
          }
        }
      }
    } else {
      // If no children is found and newEntry contains checkboxes, update them directly
      if (Array.isArray(newEntry.response)) {
        if (newEntry.questionID === incomingData.questionID) {
          let responseToReplace = newEntry.response.find(
            (item: QuestionnaireResponseInterface) =>
              item.questionID === incomingData.questionID,
          );

          if (responseToReplace) {
            const updatedResponseToReplace = {
              ...responseToReplace, // Copy the existing properties
              response: incomingData.response, // Update the response property
            };

            responseToReplace = updatedResponseToReplace;

            const filtereDataWithPreviousResponseRemoved =
              newEntry.response.filter(
                (item: QuestionnaireResponseInterface) =>
                  !(item.questionID === responseToReplace?.questionID),
              );

            newEntry.response = [
              ...filtereDataWithPreviousResponseRemoved,
              responseToReplace,
            ];
          }
        }
      }
    }

    // remove text entry from state if empty response on sign up provider

    if (id === questionnairesIds.SIGN_UP_PROVIDER) {
      if (Array.isArray(response)) {
        if (response[0].response?.toString().length === 0) {
          setPersistentData((prevState) =>
            removeParentEntriesFromPersistentData(prevState, [questionID]),
          );
        }
      }
    }
  };

  const handleInputChange: HandleOnInputChangeType = (
    response,
    questionID,
    childrenInputResponse,
    childrenInputParentID,
    childrenOriginalQuestionID,
    thirdLevelNesting,
  ) => {
    delayedHandleOnInputChange(
      response,
      questionID,
      childrenInputResponse,
      childrenInputParentID,
      childrenOriginalQuestionID,
      thirdLevelNesting,
    );
  };

  const delayedHandleOnInputChange = debounce(handleOnInputChange, 100);

  const handleOnDropdownWithMultiSelect = (
    value: string[],
    questionID: number,
    responseProperty?: string,
  ) => {
    const incomingData: QuestionnaireResponseInterface = {
      currentSection: currentSection.name,
      currentIndexOfSection: currentIndexOfSection.index,
      response: value as childrenInputResponseTypes,
      questionID: questionID,
    };
    // Find matching current index
    const matchingIndex = persistentData.findIndex(
      (entry: QuestionnaireResponseInterface) =>
        entry.currentSection === incomingData.currentSection &&
        entry.questionID === incomingData.questionID,
    );

    // 1st level of question
    if (matchingIndex === -1) {
      const newData: QuestionnaireResponseInterface = {
        currentSection: incomingData.currentSection,
        currentIndexOfSection: incomingData.currentIndexOfSection,
        questionID: incomingData.questionID,
        response: responseProperty
          ? [
              {
                response: incomingData.response,
                responsePropertyValue: responseProperty,
                questionID: incomingData.questionID,
              },
            ]
          : incomingData.response,
      };
      const newPersistentData = [...persistentData, newData];

      setPersistentData(newPersistentData);
      return;
    }

    const existingEntry =
      matchingIndex !== -1 ? persistentData[matchingIndex] : null;
    // If a response exists, replace it, otherwise create a new one
    const newEntry: QuestionnaireResponseInterface = existingEntry
      ? {
          ...existingEntry,
          response: responseProperty
            ? [
                {
                  response: incomingData.response,
                  responsePropertyValue: responseProperty,
                  questionID: incomingData.questionID,
                },
              ]
            : incomingData.response,
        }
      : {
          currentSection: incomingData.currentSection,
          currentIndexOfSection: incomingData.currentIndexOfSection,
          questionID: incomingData.questionID,
          response: responseProperty
            ? [
                {
                  response: incomingData.response,
                  responsePropertyValue: responseProperty,
                  questionID: incomingData.questionID,
                },
              ]
            : incomingData.response,
        };
    const updatedData = [...persistentData];

    updatedData[matchingIndex] = newEntry;

    if ((updatedData[matchingIndex].response as string[]).length === 0) {
      updatedData.splice(matchingIndex, 1);
    }

    setPersistentData(updatedData);
  };

  const handleOnDoubleTextField = (
    value: QuestionnaireResponseInterface[],
    questionID: number,
  ) => {
    const incomingData: QuestionnaireResponseInterface = {
      currentSection: currentSection.name,
      currentIndexOfSection: currentIndexOfSection.index,
      response: value,
      questionID: questionID,
    };
    // Find matching current index
    const matchingIndex = persistentData.findIndex(
      (entry: QuestionnaireResponseInterface) =>
        entry.currentSection === incomingData.currentSection &&
        entry.questionID === incomingData.questionID,
    );

    const textFieldResponsesWithInputIdInStorage = textFieldResponses.filter(
      (item) => {
        if ('inputId' in item) {
          return true;
        }
        return false;
      },
    );

    // 1st level of question
    if (matchingIndex === -1) {
      const newData: QuestionnaireResponseInterface = {
        currentSection: incomingData.currentSection,
        currentIndexOfSection: incomingData.currentIndexOfSection,
        questionID: incomingData.questionID,
        response: incomingData.response,
      };
      const newPersistentData = [...persistentData, newData];

      setPersistentData(newPersistentData);
      return;
    }

    const existingEntry =
      matchingIndex !== -1 ? persistentData[matchingIndex] : null;
    // If a response exists, replace it, otherwise create a new one
    const newEntry: QuestionnaireResponseInterface = existingEntry
      ? {
          ...existingEntry,
          response:
            textFieldResponsesWithInputIdInStorage.length === 1
              ? ([
                  {
                    inputId: textFieldResponsesWithInputIdInStorage[0].inputId,
                    response:
                      textFieldResponsesWithInputIdInStorage[0].response,
                    inputLabel:
                      textFieldResponsesWithInputIdInStorage[0].inputLabel,
                    questionID:
                      textFieldResponsesWithInputIdInStorage[0].questionID,
                  },
                  incomingData.response,
                ].flat() as childrenInputResponseTypes)
              : incomingData.response,
        }
      : {
          currentSection: incomingData.currentSection,
          currentIndexOfSection: incomingData.currentIndexOfSection,
          questionID: incomingData.questionID,
          response: incomingData.response,
        };
    const updatedData = [...persistentData];

    updatedData[matchingIndex] = newEntry;
    setPersistentData(updatedData);
  };

  const getFirstIncompleteSection = useCallback(
    (array: DynamicQuestionnaireSectionProgress[]) => {
      const foundArray = array.find((entry) => !entry.complete);
      let navigateToIndex = 0;

      if (foundArray) {
        const index =
          response &&
          response?.data.findIndex((item) => item.name === foundArray.name);
        navigateToIndex = index;
      }

      return navigateToIndex;
    },
    [response],
  );

  const getIncompleteMainQuestion = (): string[] => {
    const missingQuestionId: string[] = [];

    const filteredData = questionnaireData.filter((section) => {
      if (section.currentSection === currentSection.name) {
        return section;
      }
    });

    requiredIds.forEach((requiredParentId) => {
      if (!doesMainQuestionResponseExist(filteredData, requiredParentId)) {
        missingQuestionId.push(requiredParentId);
      }
    });
    return missingQuestionId;
  };

  const getIncompleteNestedQuestions = (): RequiredNestedIdsList => {
    const missingQuestionId: RequiredNestedIdsList = [];

    const filteredData = questionnaireData.filter((section) => {
      if (section.currentSection === currentSection.name) {
        return section;
      }
    });

    nestedRequiredIds.forEach((nestedRequiredId) => {
      const parentId = Object.keys(nestedRequiredId)[0];
      const questionId = nestedRequiredId[parentId];

      if (
        !doesNestedQuestionResponseExist(filteredData, parentId, questionId)
      ) {
        missingQuestionId.push(nestedRequiredId);
      }
    });

    return missingQuestionId;
  };

  const checkAllCompleteSections = useCallback(
    (array: DynamicQuestionnaireSectionProgress[]) => {
      return array.every((entry) => entry.complete);
    },
    [],
  );

  const handleRedirection = (referringPage: string) => {
    const validRoutes = [
      DASHBOARD,
      MY_SKIN,
      TEST_KITS_MANAGEMENT_TEST_KITS,
      NOTIFICATIONS,
      NOTIFICATIONS_UNREAD,
    ];
    if (validRoutes.indexOf(referringPage) !== -1) {
      return navigate(`${referringPage}?successToSaveQuestionnaire=true`);
    }
    return navigate(MY_ACCOUNT_PROFILE, {
      replace: true,
      state: { successToSaveQuestionnaire: true },
    });
  };

  const [enableUpdateQuestionnaire, setEnableUpdateQuestionnaire] =
    useState(false);

  const handleOnSubmit = async (enableReferringPage = true) => {
    try {
      if (id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT) {
        if (
          !calculateLastSectionPosition ||
          !checkAllCompleteSections(sectionsProgress)
        ) {
          setSubmitWasTriggered(true);
          setAlertMessage(locale.completeTheQuestionnaire);
          setAlertType('warning');
          scrollToTop();
          return;
        }

        if (dnaVisitRecordConsentDocument) {
          const userHasAcceptedDnaVisitConsent = await hasAcceptedLegalDocument(
            dnaVisitRecordConsentDocument,
            loggedUser?.uuid as string,
          );

          if (!userHasAcceptedDnaVisitConsent) {
            navigate(DNA_VISIT_TERMS_OF_USE, {
              state: {
                id,
                response,
                testKitData,
                questionnaireStoredData,
                enableSubmitCheckbox,
                questionnaireData,
                persistentData,
                locale,
              },
            });
          } else {
            // saves DNA visit questionnaire (reigster test kit and questionnaire resposnes)
            submitDnaTestQuestionnaire(
              testKitData as TestKitData,
              id,
              persistentData,
              locale,
              location.state,
            );
          }
        }
      } else if (id === questionnairesIds.HEALTH_QUESTIONNAIRE) {
        const isSectionsComplete =
          sectionsProgress.length === response.data.length &&
          checkAllCompleteSections(sectionsProgress);
        const isLastSection = calculateLastSectionPosition === true;
        if (enableUpdateQuestionnaire) {
          const responseUpdate = await insertFhirQuestionnaireResponses({
            variables: {
              createQuestionnaireResponsesInput: {
                codexPatientId: loggedUser?.uuid || '',
                questionnaireName: FhirQuestionnaireNames.HealthQuestionnaire,
                questionnaireResponse: persistentData,
                serviceRequestId: '',
              },
            },
          });

          if (!responseUpdate.data) {
            setAlertMessage(
              dynamicQuestionnaireLocale.questionnaireFailedToUpdate,
            );
            setAlertType('warning');
            scrollToTop();
            throw new Error('Failed to update questionnaire');
          }

          if (responseUpdate.data) {
            setEnableUpdateQuestionnaire(true);
          }

          if (isDnaTestKitsFlow) {
            return navigate(TEST_KITS_MANAGEMENT_TEST_KITS, {
              state: { dnaFlowCompleted: true },
            });
          }

          return enableReferringPage && handleRedirection(referringPage);
        } else if (!enableUpdateQuestionnaire) {
          const responseUpdate = await insertFhirQuestionnaireResponses({
            variables: {
              createQuestionnaireResponsesInput: {
                codexPatientId: loggedUser?.uuid || '',
                questionnaireName: FhirQuestionnaireNames.HealthQuestionnaire,
                questionnaireResponse: persistentData,
                serviceRequestId: '',
              },
            },
          });
          const response =
            responseUpdate.data?.insertFHIRQuestionnaireResponse
              .questionnaire_response;

          if (response) {
            setEnableUpdateQuestionnaire(true);
          }
          if (!response) {
            setAlertMessage(locale.failedToSaveQuestionnaire);
            setAlertType('warning');
            scrollToTop();
            throw new Error('Failed to save questionnaire');
          }
        }

        await updateProfileNotification();

        if (isSectionsComplete && isLastSection) {
          if (isDnaTestKitsFlow) {
            if (!userCompletedData.profile) {
              return navigate(MY_ACCOUNT_PROFILE, {
                state: {
                  section: ProfileSectionTypes.PROFILE,
                  isDnaTestKitsFlow: true,
                },
              });
            }
            if (!userCompletedData.contact) {
              return navigate(MY_ACCOUNT_PROFILE, {
                state: {
                  section: ProfileSectionTypes.CONTACT,
                  isDnaTestKitsFlow: true,
                },
              });
            }
            return navigate(TEST_KITS_MANAGEMENT_TEST_KITS, {
              state: { dnaFlowCompleted: true },
            });
          }
        }

        enableReferringPage && handleRedirection(referringPage);
      } else if (id === questionnairesIds.SIGN_UP_PROVIDER) {
        setSubmitWasTriggered(true);
        const lockedPersistentData = [...persistentData];
        const processedData = processSignUpProviderForm(lockedPersistentData);

        try {
          await handleGoogleSignupWithCredentialsForProvider(
            processedData.email,
            processedData.password,
            processedData,
          );
        } catch (e) {
          console.error(e);
        }
      } else if (
        id === questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE ||
        id === questionnairesIds.HORMONE_QUESTIONNAIRE_MALE
      ) {
        if (dnaVisitRecordConsentDocument) {
          const userHasAcceptedDnaVisitConsent = await hasAcceptedLegalDocument(
            dnaVisitRecordConsentDocument,
            loggedUser?.uuid as string,
          );

          if (!userHasAcceptedDnaVisitConsent) {
            navigate(HORMONE_VISIT_TERMS_OF_USE, {
              state: {
                id,
                response,
                testKitData,
                questionnaireStoredData,
                enableSubmitCheckbox,
                questionnaireData,
                persistentData,
                locale,
              },
            });
          } else {
            submitZrtQuestionnaireResponses();
          }
        }
      }
    } catch (error: unknown) {
      setSubmitWasTriggered(true);
      const getIncompleteSection = getFirstIncompleteSection(sectionsProgress);

      const newSection: DynamicQuestionnaireCurrentIndexOfSection = {
        index: getIncompleteSection,
        length: currentIndexOfSection.length,
      };

      console.log(error);
      setAlertMessage(locale.failedToSaveQuestionnaire);
      setCurrentIndexOfSection(newSection);
      setCurrentSection(response.data[getIncompleteSection]);
      setAlertType('warning');
      scrollToTop();
      throw new Error('Failed to save questionnaire');
    }
  };

  useEffect(() => {
    if (submitWasTriggered && !loadingProvider) {
      setSubmitWasTriggered(false);
    }
  }, [loadingProvider, submitWasTriggered]);

  useEffect(() => {
    if (providerErrorCode === FIREBASE_ERROR_CODES.AUTH_EMAIL_ALREADY_IN_USE) {
      const newSection: DynamicQuestionnaireCurrentIndexOfSection = {
        index: 0,
        length: currentIndexOfSection.length,
      };
      setAlertMessage(signUpProviderFirebaseError);
      setCurrentIndexOfSection(newSection);
      setCurrentSection(response.data[0]);
      setAlertType('warning');
      scrollToTop();
    } else if (signUpProviderFirebaseError) {
      setAlertMessage(signUpProviderFirebaseError);
      setAlertType('warning');
      scrollToTop();
    }
  }, [
    currentIndexOfSection.length,
    providerErrorCode,
    response.data,
    signUpProviderFirebaseError,
  ]);

  useEffect(() => {
    if (providerData.uid && !providerErrorCode) {
      navigate(AUTH_SEND_VERIFICATION_EMAIL, { replace: true });
    }
  }, [providerErrorCode, submitWasTriggered, providerData, navigate]);

  const filterResponsesOfDisabledFields = (
    persistentData: QuestionnaireResponseInterface[],
  ): QuestionnaireResponseInterface[] => {
    const globalIds = Object.values(
      globalNestedRequiredIds as GlobalRequiredIdsList,
    ).reduce((result, propValues) => result.concat(propValues), []);

    const filteredData = persistentData.map((response) => {
      const mainQuestionId = response.questionID;
      if (typeof response.response === 'string') {
        return response;
      }

      if (Array.isArray(response.response) && response.response.length) {
        response.response = response.response.filter((item) => {
          return (
            item.questionID === mainQuestionId ||
            globalIds.some((dependencie) => {
              return (
                Object.entries(dependencie).toString() ===
                Object.entries({
                  [mainQuestionId as number]:
                    item.questionID?.toString() as string,
                }).toString()
              );
            })
          );
        });

        return response;
      }

      return response;
    });

    return filteredData;
  };

  const submitDnaTestQuestionnaire = async (
    testKitData: TestKitData,
    questionnaireId: number,
    persistentData: QuestionnaireResponseInterface[],
    locale: Record<string, string>,
    state: object,
  ): Promise<void> => {
    const { data } = await getDnaTestBySwabCodeIDWithNoErrors({
      variables: { swabCode: testKitData.testKitIdNumber },
    });
    if (data && data.getDnaTestBySwabCodeIDWithNoErrors.existingDnaTests) {
      navigate(TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL, { state });
      setAlertMessage(locale.testKitAlreadyInProcess);
      setAlertType('warning');
      return scrollToTop();
    }

    const mutationVariables: RegisterTestKitMutationVariables = {
      questionnaireId,
      swabBarcode: testKitData.testKitIdNumber,
      questionnaireResponses: filterResponsesOfDisabledFields(
        _.cloneDeep(persistentData),
      ),
      testType: testKitData.testType,
    };

    if (testKitData.testKitTrackingNumber) {
      mutationVariables.trackingNumber = testKitData.testKitTrackingNumber;
    }

    const response = await registerTestKitMutation({
      variables: mutationVariables,
    });

    if (!response.data) {
      navigate(TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL, { state });
      setAlertMessage(locale.failedToSaveQuestionnaire);
      setAlertType('warning');
      scrollToTop();
      throw new Error('Failed to save questionnaire');
    }
    navigate(TEST_KITS_MANAGEMENT_TEST_KITS, {
      state: { alert: true, ...testKitData },
    });
    await clearAllSavedDnaScannedDataForUserIdMutation().catch((e) =>
      console.log('Error when cleaning saved DNA data', e),
    );
  };

  const submitZrtQuestionnaireResponses = async (): Promise<void> => {
    const castedTestKit = testKitData as TestKitData;

    const responseUpdate = await insertFhirQuestionnaireResponses({
      variables: {
        createQuestionnaireResponsesInput: {
          codexPatientId: loggedUser?.uuid || '',
          questionnaireName: FhirQuestionnaireNames.ZrtQuestionnaire,
          questionnaireResponse: persistentData,
          serviceRequestId: '',
        },
      },
    });

    const mutationVariables: RegisterTestKitMutationVariables = {
      questionnaireId: id,
      swabBarcode: castedTestKit.testKitIdNumber,
      questionnaireResponses: persistentData,
      testType: castedTestKit.testType,
    };

    if (castedTestKit.testKitTrackingNumber) {
      mutationVariables.trackingNumber = castedTestKit.testKitTrackingNumber;
    }

    const response = await registerTestKitMutation({
      variables: mutationVariables,
    });

    if (!response.data) {
      navigate(HORMONE_QUESTIONNAIRE_FULL, { state: testKitData });
      setAlertMessage(locale.failedToSaveQuestionnaire);
      setAlertType('warning');
      scrollToTop();
      throw new Error('Failed to save questionnaire');
    }

    if (!responseUpdate.data) {
      setAlertMessage(dynamicQuestionnaireLocale.questionnaireFailedToUpdate);
      setAlertType('warning');
      scrollToTop();
      throw new Error('Failed to update questionnaire');
    }

    navigate(DASHBOARD, {
      state: { zrtTestkitAlert: true, testType: castedTestKit.testType },
    });
  };

  const loadRadiosFromStorage = useCallback(
    (
      questionnaireData: GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery,
    ) => {
      const data = questionnaireData.questionnaire_responses[0].responses;

      let getRadios = [];

      if (id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT) {
        const filteredRadios = data
          .map((item: QuestionnaireResponseInterface) => {
            if (Array.isArray(item.response)) {
              const results = item.response.map((data) => {
                const newItem = {
                  questionID: item.questionID,
                  ...data,
                  section: item.currentSection,
                };
                return newItem;
              });
              return results;
            }

            if (typeof item.response === 'object') {
              if (Array.isArray(item.response)) {
                if (
                  item.response[0].response === 'All of the below' ||
                  item.response[0].response === 'None of the below' ||
                  item.response[0].response === 'Yes' ||
                  item.response[0].response === 'No'
                ) {
                  const newItem = {
                    section: item.currentSection,
                    response: item.response[0].response,
                    questionID: item.response[0].questionID,
                  };
                  return newItem;
                } else if (Array.isArray(item.response[0].response)) {
                  return;
                }
              }
            }

            if (typeof item.response === 'string') {
              if (
                item.response.includes('All of the below') ||
                item.response.includes('None of the below') ||
                item.response.includes('weather related skin flare-ups') ||
                item.response.includes('I do not know')
              ) {
                const newItem = {
                  ...item,
                  section: item.currentSection,
                };
                return newItem;
              }
              return item;
            }
            return item;
          })
          .flat();
        getRadios = filteredRadios.filter(
          (item: QuestionnaireResponseInterface) =>
            item !== undefined && !Array.isArray(item.response),
        );
      }

      if (id === questionnairesIds.HEALTH_QUESTIONNAIRE) {
        getRadios = data.filter((item: QuestionnaireResponseInterface) => {
          if (typeof item.response === 'string') {
            return item;
          }
        });
      }

      return getRadios;
    },
    [id],
  );

  const loadCheckboxesFromStorage = useCallback(
    (
      questionnaireData: GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery,
    ) => {
      const data = questionnaireData.questionnaire_responses[0].responses;

      let getCheckboxes: QuestionnaireResponseInterface[] = [];
      const processedCheckboxes: QuestionnaireResponseInterface[] = [];

      if (id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT) {
        getCheckboxes = data.map((item: QuestionnaireResponseInterface) => {
          if (Array.isArray(item.response)) {
            const itemResponseArray = item.response.find((data) => {
              return Array.isArray(data.response) === true;
            });

            if (item.response.length >= 1) {
              item.response.forEach((data) => {
                if (
                  typeof data.response === 'string' &&
                  data.questionID === item.questionID
                ) {
                  const newItem = {
                    ...item,
                    section: item.currentSection,
                    response: data.response,
                    checked: true,
                  };
                  processedCheckboxes.push(newItem);
                }
              });
            }

            if (Array.isArray(itemResponseArray?.response)) {
              const items = itemResponseArray?.response;

              items?.forEach((data) => {
                const newItem = {
                  questionID: data.questionID,
                  section: item.currentSection,
                  response: data.response,
                  checked: true,
                };
                processedCheckboxes.push(newItem);
              });
            }
          }
        });

        getCheckboxes = processedCheckboxes;
      }
      return getCheckboxes;
    },
    [id],
  );

  const loadSelectsFromStorage = (
    questionnaireData: GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery,
  ) => {
    const data = questionnaireData.questionnaire_responses[0].responses;

    const getSelects = data.filter((item: QuestionnaireResponseInterface) => {
      if (Array.isArray(item.response)) {
        return item.response.every((value) => typeof value === 'string');
      }
      return false;
    });

    return getSelects;
  };

  const loadMedicationsDataFromStorage = (
    questionnaireData: GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery,
  ) => {
    const data = questionnaireData.questionnaire_responses[0].responses;
    const filteredMedicationList = data
      .filter((item: QuestionnaireResponseInterface) => item.questionID === 99)
      .map((item: QuestionnaireResponseInterface) => item.response);

    return filteredMedicationList[0];
  };

  const loadTextFieldsDataFromStorage = useCallback(
    (
      questionnaireData: GetQuestionnaireResponsesByUserAndQuestionnaireIdQuery,
    ) => {
      const data = questionnaireData.questionnaire_responses[0].responses;
      const textFieldsFound: QuestionnaireResponseInterface[] = [];

      if (id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT) {
        data.forEach((responseItem: QuestionnaireResponseInterface) => {
          if (
            Array.isArray(responseItem.response) &&
            responseItem.response.length > 1
          ) {
            const textAnswer = responseItem.response.find((data) => {
              return (
                (typeof data.response === 'string' && data.questionID === 16) ||
                data.questionID === 19
              );
            });

            const newTextAnswer = {
              ...textAnswer,
              questionID: responseItem.questionID,
            };
            textAnswer && textFieldsFound.push(newTextAnswer);
          }
        });
      }

      if (id === questionnairesIds.HEALTH_QUESTIONNAIRE) {
        data.forEach((responseItem: QuestionnaireResponseInterface) => {
          if (Array.isArray(responseItem.response)) {
            responseItem.response.forEach(
              (subItem: QuestionnaireResponseInterface) => {
                if (Object.prototype.hasOwnProperty.call(subItem, 'inputId')) {
                  textFieldsFound.push(subItem);
                }
              },
            );
          }
        });
      }
      return textFieldsFound;
    },
    [id],
  );
  const { isOpen: isResultsPrivacyStatementOpen } = useModalParams(
    'results-privacy-statement',
  );

  const { isOpen: isDnaVisitTermsOfUseOpen } = useModalParams(
    'dna-visit-terms-of-use',
  );

  const { isOpen: isHormoneVisitTermsOfUseOpen } = useModalParams(
    'hormone-visit-terms-of-use',
  );

  const openResultsPrivacyStatement = () => {
    if (enableSubmitCheckbox && enableSubmitCheckbox.link) {
      navigate(enableSubmitCheckbox.link, {
        state: { testKitData },
      });
    }
  };

  const calculateLastSectionPosition = useMemo(() => {
    return currentIndexOfSection.length === currentIndexOfSection.index + 1;
  }, [currentIndexOfSection]);

  const determineSubmitAction: () => () => void = () => {
    switch (id) {
      case questionnairesIds.HORMONE_QUESTIONNAIRE_MALE:
        return async () => {
          const isSectionsComplete =
            sectionsProgress.length === response.data.length &&
            checkAllCompleteSections(sectionsProgress);

          const isLastSection = calculateLastSectionPosition === true;
          if (isSectionsComplete && isLastSection) {
            handleOnSubmit();
          } else if (!isSectionsComplete && isLastSection) {
            setSubmitWasTriggered(true);
            const getIncompleteSection =
              getFirstIncompleteSection(sectionsProgress);
            const newSection: DynamicQuestionnaireCurrentIndexOfSection = {
              index: getIncompleteSection,
              length: currentIndexOfSection.length,
            };
            setCurrentSection(response.data[getIncompleteSection]);
            setCurrentIndexOfSection(newSection);
            setAlertMessage(locale.completeTheQuestionnaire);
            setIsSidebarBlocked(true);
            setAlertType('warning');
            scrollToTop();
          } else {
            if (!isCurrentSectionComplete) {
              setIsSidebarBlocked(true);
              setAlertMessage(dynamicQuestionnaireLocale.fillCurrentSection);
              setAlertType('warning');
              scrollToTop();
              return;
            }
            setIsSidebarBlocked(false);
            getNextSection();
          }
        };
      case questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE:
        return async () => {
          const isSectionsComplete =
            sectionsProgress.length === response.data.length &&
            checkAllCompleteSections(sectionsProgress);

          const isLastSection = calculateLastSectionPosition === true;

          if (isSectionsComplete && isLastSection) {
            handleOnSubmit();
          } else if (!isSectionsComplete && isLastSection) {
            setSubmitWasTriggered(true);
            const getIncompleteSection =
              getFirstIncompleteSection(sectionsProgress);
            const newSection: DynamicQuestionnaireCurrentIndexOfSection = {
              index: getIncompleteSection,
              length: currentIndexOfSection.length,
            };
            setCurrentSection(response.data[getIncompleteSection]);
            setCurrentIndexOfSection(newSection);
            setAlertMessage(locale.completeTheQuestionnaire);
            setIsSidebarBlocked(true);
            setAlertType('warning');
            scrollToTop();
          } else {
            if (!isCurrentSectionComplete) {
              setIsSidebarBlocked(true);
              setAlertMessage(dynamicQuestionnaireLocale.fillCurrentSection);
              setAlertType('warning');
              scrollToTop();
              return;
            }
            setIsSidebarBlocked(false);
            getNextSection();
          }
        };
      case questionnairesIds.HEALTH_QUESTIONNAIRE:
        // Handle the HEALTH_QUESTIONNAIRE case
        return async () => {
          const isSectionsComplete =
            sectionsProgress.length === response.data.length &&
            checkAllCompleteSections(sectionsProgress);
          const isLastSection = calculateLastSectionPosition === true;

          const evaluateResult = await evaluateDataChangeOnQuestionnaireData();
          if (isSectionsComplete && isLastSection) {
            handleOnSubmit();
          } else {
            if (
              evaluateResult !== undefined &&
              evaluateResult === false &&
              isSectionsComplete
            ) {
              handleOnSubmit(false);
              setAlertMessage(locale.healthQuestionnaireUpdatedSuccessfully);
              setAlertType('positive');
              getNextSection(false);
            } else {
              getNextSection();
            }
          }
        };
      case questionnairesIds.SIGN_UP_PROVIDER:
        return async () => {
          const isSectionsComplete =
            sectionsProgress.length === response.data.length &&
            checkAllCompleteSections(sectionsProgress);
          const isLastSection = calculateLastSectionPosition === true;

          if (isSectionsComplete && isLastSection) {
            handleOnSubmit();
          } else if (!isSectionsComplete && isLastSection) {
            setSubmitWasTriggered(true);
            const getIncompleteSection =
              getFirstIncompleteSection(sectionsProgress);
            const newSection: DynamicQuestionnaireCurrentIndexOfSection = {
              index: getIncompleteSection,
              length: currentIndexOfSection.length,
            };
            setCurrentSection(response.data[getIncompleteSection]);
            setCurrentIndexOfSection(newSection);
            setAlertMessage(locale.completeTheQuestionnaire);
            setIsSidebarBlocked(true);
            setAlertType('warning');
            scrollToTop();
          } else {
            if (!isCurrentSectionComplete) {
              setIsSidebarBlocked(true);
              setAlertMessage(dynamicQuestionnaireLocale.fillCurrentSection);
              setAlertType('warning');
              scrollToTop();
              return;
            }
            setIsSidebarBlocked(false);
            getNextSection();
          }
        };
      case questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT:
        // Handle the REGISTER_MY_DNA_SKIN_TEST_KIT case
        return async () => {
          const isSectionsComplete =
            sectionsProgress.length === response.data.length &&
            checkAllCompleteSections(sectionsProgress);
          const isLastSection = calculateLastSectionPosition === true;

          if (isSectionsComplete && isLastSection) {
            handleOnSubmit();
          } else if (!isSectionsComplete && isLastSection) {
            setSubmitWasTriggered(true);
            const getIncompleteSection =
              getFirstIncompleteSection(sectionsProgress);
            const newSection: DynamicQuestionnaireCurrentIndexOfSection = {
              index: getIncompleteSection,
              length: currentIndexOfSection.length,
            };
            setCurrentSection(response.data[getIncompleteSection]);
            setCurrentIndexOfSection(newSection);
            setAlertMessage(locale.completeTheQuestionnaire);
            setAlertType('warning');
            scrollToTop();
          } else {
            if (!isCurrentSectionComplete) {
              setIsSidebarBlocked(true);
              setAlertMessage(dynamicQuestionnaireLocale.fillCurrentSection);
              setAlertType('warning');
              scrollToTop();
              return;
            }
            setIsSidebarBlocked(false);
            getNextSection();
          }
        };
      default:
        return () => {
          if (
            sectionsProgress.length === response.data.length &&
            checkAllCompleteSections(sectionsProgress) &&
            calculateLastSectionPosition === true
          ) {
            handleOnSubmit();
          } else {
            getNextSection();
          }
        };
    }
  };

  const getCTAActionLabelMemoized = useMemo(
    () =>
      (
        id: number,
        sectionsProgress: DynamicQuestionnaireSectionProgress[],
        response: DynamicQuestionnaireResponse,
        calculateLastSectionPosition: boolean,
      ) => {
        if (id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT) {
          return genericAction?.[genericActionsIds.CONTINUE]?.continue;
        }

        return sectionsProgress.length === response.data.length &&
          checkAllCompleteSections(sectionsProgress) &&
          calculateLastSectionPosition === true
          ? id === questionnairesIds.SIGN_UP_PROVIDER
            ? genericAction?.[genericActionsIds.SUBMIT_FORM]?.submitSignUpForm
            : genericAction?.[genericActionsIds.SUBMIT]?.submit
          : genericAction?.[genericActionsIds.CONTINUE]?.continue;
      },
    [genericAction, checkAllCompleteSections],
  );

  useEffect(() => {
    setCurrentIndexOfSection(getCurrentSectionPosition);
  }, [response, currentSection, getCurrentSectionPosition, questionnaireData]);

  useEffect(() => {
    lengthValidationPerSection();
  }, [
    questionnaireData,
    currentSection.name,
    currentSection.properties.length,
    lengthValidationPerSection,
  ]);

  useEffect(() => {
    setQuestionnaireData(persistentData);
  }, [persistentData]);

  const prevPersistentData = useRef(persistentData);
  const isFirstRenderRefetch = useRef(true);

  const {
    data: latestIncompleteFHIRQuestionnaire,
    loading: loadingLatestIncompleteFHIRQuestionnaire,
  } = useGetLatestFhirQuestionnaireByIdQuery({
    variables: {
      questionnaire_id: questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT,
    },
  });

  const storedIdToUpdate: string | undefined = (
    questionnaireStoredData?.questionnaire_responses[0] as
      | { id: string }
      | undefined
  )?.id;

  const [questionnaireToUpdate, setQuestionnaireToUpdate] = useState<
    string | null
  >(
    storedIdToUpdate
      ? storedIdToUpdate
      : (!loadingLatestIncompleteFHIRQuestionnaire &&
          latestIncompleteFHIRQuestionnaire?.getLatestFHIRQuestionnaireById
            ?.questionnaire_responses[0] &&
          latestIncompleteFHIRQuestionnaire?.getLatestFHIRQuestionnaireById
            ?.questionnaire_responses[0].id) ||
          null,
  );

  useEffect(() => {
    if (id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT) {
      if (isFirstRenderRefetch.current) {
        isFirstRenderRefetch.current = false;
        const saveAsync = async () => {
          const saveQuestionnaireManuallyPetition =
            await saveFhirQuestionnaireManually({
              variables: {
                questionnaireId: id,
                responses: persistentData,
                userID: loggedUser?.uuid || '',
                isComplete: false,
              },
            });

          if (!saveQuestionnaireManuallyPetition.data) {
            setAlertMessage(
              dynamicQuestionnaireLocale.questionnaireFailedToUpdate,
            );
            setAlertType('warning');
            scrollToTop();
          }

          setQuestionnaireToUpdate(
            saveQuestionnaireManuallyPetition.data
              ?.saveFHIRQuestionnaireManually?.id || null,
          );
        };

        if (!questionnaireToUpdate) {
          saveAsync();
        }
      }
    }
  }, [
    dynamicQuestionnaireLocale,
    id,
    loggedUser?.uuid,
    persistentData,
    questionnaireToUpdate,
    saveFhirQuestionnaireManually,
  ]);

  useEffect(() => {
    const handleProgressiveUpdateOfQuestionnaire = async () => {
      if (questionnaireToUpdate) {
        // update current entry in questionnaire

        const mutationVariables = {
          id: questionnaireToUpdate,
          responses: persistentData,
          isComplete: false,
        };

        const response = await updateFHIRUserLatestQuestionnaireMutation({
          variables: mutationVariables,
        });

        if (!response.data) {
          setAlertMessage(
            dynamicQuestionnaireLocale.questionnaireFailedToUpdate,
          );
          setAlertType('warning');
          scrollToTop();
        }
      }
    };

    if (prevPersistentData.current !== persistentData) {
      prevPersistentData.current = persistentData;
      if (
        id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT &&
        persistentData.length >= 1
      ) {
        handleProgressiveUpdateOfQuestionnaire();
      }
    }
  }, [
    dynamicQuestionnaireLocale,
    id,
    persistentData,
    questionnaireToUpdate,
    updateFHIRUserLatestQuestionnaireMutation,
  ]);

  useEffect(() => {
    if (
      id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT &&
      hasQuestionnaireResponsesInStorage &&
      !persistentData.length
    ) {
      setPersistentData(
        questionnaireStoredData.questionnaire_responses[0].responses,
      );

      setRadioCheckedState(loadRadiosFromStorage(questionnaireStoredData));
      setCheckboxesCheckedState(
        loadCheckboxesFromStorage(questionnaireStoredData),
      );
      setTextFieldResponses(
        loadTextFieldsDataFromStorage(questionnaireStoredData),
      );

      setSectionsProgress(calculateAllSidebarItemsProgress);
    }

    if (
      id === questionnairesIds.HEALTH_QUESTIONNAIRE &&
      hasQuestionnaireResponsesInStorage &&
      !persistentData.length
    ) {
      setPersistentData(
        questionnaireStoredData.questionnaire_responses[0].responses,
      );
      setSelectsFromStorage(loadSelectsFromStorage(questionnaireStoredData));
      setTextFieldResponses(
        loadTextFieldsDataFromStorage(questionnaireStoredData),
      );
      setMedicationsDataFromStorage(
        loadMedicationsDataFromStorage(questionnaireStoredData),
      );
      setRadioCheckedState(loadRadiosFromStorage(questionnaireStoredData));
      setSectionsProgress(calculateAllSidebarItemsProgress);
    }
  }, [
    questionnaireStoredData,
    id,
    calculateAllSidebarItemsProgress,
    hasQuestionnaireResponsesInStorage,
    loadRadiosFromStorage,
    loadCheckboxesFromStorage,
    loadTextFieldsDataFromStorage,
    persistentData.length,
  ]);

  useEffect(() => {
    if (
      id === questionnairesIds.HEALTH_QUESTIONNAIRE ||
      id === questionnairesIds.REGISTER_MY_DNA_SKIN_TEST_KIT
    ) {
      const validateUse = async () => {
        const {
          isContactInfoValid,
          isPersonalInfoValid,
          isQuestionnaireValid,
        } = await userValidation();
        setUserCompletedData({
          contact: isContactInfoValid,
          healthQuestionnaire: isQuestionnaireValid,
          profile: isPersonalInfoValid,
        });
      };
      validateUse();
    }
  }, [id, userValidation]);

  // Handle sidebar validations based on validations from form module
  useEffect(() => {
    if (
      id === questionnairesIds.SIGN_UP_PROVIDER &&
      moduleFormErrors &&
      moduleFormErrors?.sectionsHasErrors &&
      moduleFormErrors?.sectionsHasErrors?.length > 0
    ) {
      const markSectionsIncomplete = (
        sectionsErrors: string[],
        sectionsData: SectionsData[],
      ): SectionsData[] => {
        return sectionsData.map((section) => ({
          ...section,
          complete: !sectionsErrors.includes(section.name),
        }));
      };

      // Check if the sectionsProgress needs to be updated
      const updatedSectionsData = markSectionsIncomplete(
        moduleFormErrors?.sectionsHasErrors || [],
        sectionsProgress,
      );

      // Check if there is a change in the sectionsProgress before updating
      if (!_.isEqual(updatedSectionsData, sectionsProgress)) {
        setSectionsProgress(updatedSectionsData);
      }
    }
  }, [moduleFormErrors, currentSection, sectionsProgress, id]);

  useEffect(() => {
    if (
      id === questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE &&
      moduleFormErrors &&
      moduleFormErrors?.sectionsHasErrors &&
      moduleFormErrors?.sectionsHasErrors?.length > 0
    ) {
      const markSectionsIncomplete = (
        sectionsErrors: string[],
        sectionsData: SectionsData[],
      ): SectionsData[] => {
        return sectionsData.map((section) => {
          if (
            section.name ===
            HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS
          ) {
            return {
              ...section,
              complete: !sectionsErrors.includes(section.name),
            };
          } else {
            return section;
          }
        });
      };

      // Check if the sectionsProgress needs to be updated
      const updatedSectionsData = markSectionsIncomplete(
        moduleFormErrors?.sectionsHasErrors || [],
        sectionsProgress,
      );

      // Check if there is a change in the sectionsProgress before updating
      if (!_.isEqual(updatedSectionsData, sectionsProgress)) {
        setSectionsProgress(updatedSectionsData);
      }
    }
  }, [moduleFormErrors, currentSection, sectionsProgress, id]);

  useEffect(() => {
    if (
      id === questionnairesIds.HORMONE_QUESTIONNAIRE_MALE &&
      moduleFormErrors &&
      moduleFormErrors?.sectionsHasErrors &&
      moduleFormErrors?.sectionsHasErrors?.length > 0
    ) {
      const markSectionsIncomplete = (
        sectionsErrors: string[],
        sectionsData: SectionsData[],
      ): SectionsData[] => {
        return sectionsData.map((section) => {
          if (
            section.name ===
            HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS
          ) {
            return {
              ...section,
              complete: !sectionsErrors.includes(section.name),
            };
          } else {
            return section;
          }
        });
      };

      // Check if the sectionsProgress needs to be updated
      const updatedSectionsData = markSectionsIncomplete(
        moduleFormErrors?.sectionsHasErrors || [],
        sectionsProgress,
      );

      // Check if there is a change in the sectionsProgress before updating
      if (!_.isEqual(updatedSectionsData, sectionsProgress)) {
        setSectionsProgress(updatedSectionsData);
      }
    }
  }, [moduleFormErrors, currentSection, sectionsProgress, id]);

  useEffect(() => {
    if (
      id === questionnairesIds.SIGN_UP_PROVIDER &&
      isCurrentSectionComplete === true
    ) {
      setIsSidebarBlocked(false);
    }
  }, [id, isCurrentSectionComplete]);

  const moduleFormHasErrorsInCurrentSection = useMemo(() => {
    if (
      id === questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE ||
      id === questionnairesIds.HORMONE_QUESTIONNAIRE_MALE
    ) {
      return moduleFormErrors?.sectionsHasErrors?.includes(currentSection.name);
    }
  }, [currentSection.name, id, moduleFormErrors]);

  if (isResultsPrivacyStatementOpen) {
    return (
      <ResultsPrivacyStatement
        closeTo={TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL}
        state={{
          id,
          response,
          testKitData,
          questionnaireStoredData,
          enableSubmitCheckbox,
          questionnaireData,
        }}
      />
    );
  }

  if (isDnaVisitTermsOfUseOpen) {
    return <DnaVisitTermsOfUse onSubmit={submitDnaTestQuestionnaire} />;
  }

  if (isHormoneVisitTermsOfUseOpen) {
    return (
      <ResultsPrivacyStatement
        closeTo={TEST_KITS_MANAGEMENT_QUESTIONNAIRE_FULL}
        state={{
          id,
          response,
          testKitData,
          questionnaireStoredData,
          enableSubmitCheckbox,
          questionnaireData,
        }}
      />
    );
  }

  if (
    (genericActionLoading && !genericAction) ||
    (dynamicQuestionnaireLocaleLoading && !dynamicQuestionnaireLocale) ||
    testKitsTextsLoading ||
    !locale ||
    loadingUserValidation
  )
    return null;

  return (
    <div className="flex flex-col">
      {alertMessage && (
        <div className="flex flex-row mb-[30px]">
          <AlertComponent type={alertType} text={alertMessage} />
        </div>
      )}
      <DynamicQuestionnairePreface
        title={response.title}
        subtitle={response?.subtitle}
      />
      <div className="w-full flex pt-[30px] flex-col px-7">
        <div className="w-full flex desktop:flex-row flex-col">
          <DynamicQuestionnaireSidebar
            items={sidebarItemsWithProgress}
            currentItem={currentSection.name}
            onClick={handleSidebarClick}
            currentIndexPosition={currentIndexOfSection}
            prefaceSubtitle={response.subtitle}
            isSidebarBlocked={isSidebarBlocked}
          />
          <DynamicQuestionnaireMainView
            setPersistentData={setPersistentData}
            questionnaireID={id}
            responseQuestions={response.questionnaires_questionnaire_questions}
            currentIndexSection={currentIndexOfSection}
            section={currentSection}
            questions={response.questionnaires_questionnaire_questions}
            onChange={handleInputChange}
            onDropdownWithMultiSelectChange={handleOnDropdownWithMultiSelect}
            onDoubleTextFieldChange={handleOnDoubleTextField}
            questionnaireData={questionnaireData}
            ctaLabel={getCTAActionLabelMemoized(
              id,
              sectionsProgress,
              response,
              calculateLastSectionPosition,
            )}
            disableSubmit={
              isSavingDNAQuestionnaire ||
              isFetchingRepeatedDNATests ||
              isSavingFhirQuestionnaireResponses ||
              loadingProvider ||
              (id === questionnairesIds.SIGN_UP_PROVIDER &&
                moduleFormHasErrorsInCurrentSection)
            }
            enableSubmit={determineSubmitAction()}
            selectsFromStorage={selectsFromStorage}
            medicationsDataFromStorage={medicationsDataFromStorage}
            radioCheckedState={radioCheckedState}
            setRadioCheckedState={setRadioCheckedState}
            checkboxesCheckedState={checkboxesCheckedState}
            setCheckboxesCheckedState={setCheckboxesCheckedState}
            textFieldResponses={textFieldResponses}
            setTextFieldResponses={setTextFieldResponses}
            enableSubmitCheckbox={enableSubmitCheckbox}
            onSubmitCheckboxLink={openResultsPrivacyStatement}
            calculateLastSectionPosition={calculateLastSectionPosition}
            isSubmitCheckboxChecked={isSubmitCheckboxChecked}
            handleOnSubmitCheckbox={setIsSubmitCheckboxChecked}
            requiredQuestionsIds={requiredIds}
            requiredNestedQuestionsIds={nestedRequiredIds}
            refetchData={refetchData}
            getIncompleteMainQuestion={getIncompleteMainQuestion}
            getIncompleteNestedQuestions={getIncompleteNestedQuestions}
            submitWasTriggered={submitWasTriggered}
            moduleFormErrors={moduleFormErrors}
            setModuleFormErrors={setModuleFormErrors}
            isUnathenticatedQuestionnaire={isUnauthenticatedQuestionnaire}
            persistentData={persistentData}
            hormoneTableMedicationsSelected={hormoneTableMedicationsSelected}
            setHormoneTableMedicationsSelected={
              setHormoneTableMedicationsSelected
            }
            hormoneTableCheckboxesCheckedState={
              hormoneTableCheckboxesCheckedState
            }
            setHormoneTableCheckboxesCheckedState={
              setHormoneTableCheckboxesCheckedState
            }
            hormoneTableCheckboxCheckAll={hormoneTableCheckboxCheckAll}
            setHormoneTableCheckboxAll={setHormoneTableCheckboxAll}
            testKitData={
              (testKitData as TestKitData)?.testType
                ? (testKitData as TestKitData)
                : undefined
            }
          />
        </div>
        {!calculateLastSectionPosition &&
          enableSubmitCheckbox &&
          enableSubmitCheckbox.enable &&
          enableSubmitCheckbox.showLinkOnAllViews && (
            <div
              className="mt-[30px] desktop:ml-[360px]"
              onClick={openResultsPrivacyStatement}
            >
              <span
                className={
                  enableSubmitCheckbox.highlightLink
                    ? 'text-base font-bold text-clc-blue underline cursor-pointer'
                    : ''
                }
              >
                {enableSubmitCheckbox.linkLabel}
              </span>
            </div>
          )}
      </div>
    </div>
  );
};

export default DynamicQuestionnaire;
